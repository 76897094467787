import React, { PropsWithoutRef, useState } from 'react';

// TODO account for timezones
import dayjs from 'dayjs';

// order is important
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';

import { Backdrop, Button, ClickAwayListener, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DateRangeIcon from '@mui/icons-material/DateRange';
import FilterIcon from '@mui/icons-material/FilterList';
import { formatStartEndDates } from '../services/util';

const useStyles = makeStyles(() => ({
    previewButton: {
        backgroundColor: '#fff',
        color: '#000',
        borderRadius: '4px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    dateRange: {
        padding: '15px',
        backgroundColor: 'white',
        borderRadius: '10px',
    },
}));

export type DateRangeSelectorProps = PropsWithoutRef<{
    showPreview?: boolean;
    onChange: (startDate?: Date, endDate?: Date) => void;
    displayFormat?: string;
    disabled?: boolean;
    maxDate?: Date;
}>;

const DateRangeSelector = ({
    onChange,
    displayFormat = 'MMMM DD, YYYY',
    showPreview = true,
    disabled = false,
    maxDate,
}: DateRangeSelectorProps) => {
    const classes = useStyles();

    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [previewStartDate, setPreviewStartDate] = useState(
        dayjs().subtract(7, 'day').startOf('day').toDate()
    );
    const [previewEndDate, setPreviewEndDate] = useState(
        dayjs().endOf('day').toDate()
    );
    const [showPreviewDates, setShowPreviewDates] = useState(false);

    const confirmSelection = () => {
        setShowDateRangePicker(false);
        onChange(previewStartDate, previewEndDate);
        setShowPreviewDates(true);
    };

    const cancelSelection = () => {
        setShowDateRangePicker(false);
    };

    const clearSelection = () => {
        setShowPreviewDates(false);
        setShowDateRangePicker(false);
        onChange();
    };

    const handleChange = (item: RangeKeyDict) => {
        const startDate = dayjs(item.selection.startDate)
            .startOf('day')
            .toDate();
        setPreviewStartDate(startDate);
        setPreviewEndDate(item.selection.endDate!);
    };

    const buttonText = () => {
        if (showPreviewDates) {
            const preview = formatStartEndDates(
                previewStartDate,
                previewEndDate,
                displayFormat
            );

            if (showPreview) {
                return preview;
            }
        }
        return 'Filter by Date';
    };

    return (
        <>
            <Button
                className={classes.previewButton}
                color="inherit"
                style={{
                    textTransform: 'none',
                    fontWeight: 400,
                }}
                endIcon={showPreview ? <DateRangeIcon /> : <FilterIcon />}
                onClick={() => {
                    setShowDateRangePicker(true);
                }}
                disabled={disabled}
                data-testid="date-range-filter-btn"
            >
                {buttonText()}
            </Button>
            <Backdrop
                open={showDateRangePicker}
                style={{ zIndex: 10000 }}
                data-testid="date-range-backdrop"
            >
                <ClickAwayListener
                    onClickAway={cancelSelection}
                    mouseEvent="onMouseUp"
                >
                    <Grid container direction="column" alignItems="center">
                        <Grid
                            item
                            style={{
                                margin: '0 auto',
                            }}
                            className={classes.dateRange}
                        >
                            <DateRangePicker
                                onChange={handleChange}
                                months={2}
                                ranges={[
                                    {
                                        startDate: previewStartDate,
                                        endDate: previewEndDate,
                                        key: 'selection',
                                    },
                                ]}
                                direction="horizontal"
                                minDate={new Date(2020, 9, 1)}
                                maxDate={maxDate}
                            />
                            <Grid
                                container
                                justifyContent="flex-end"
                                spacing={1}
                            >
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={confirmSelection}
                                    >
                                        Apply
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={clearSelection}
                                    >
                                        Clear
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={cancelSelection}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ClickAwayListener>
            </Backdrop>
        </>
    );
};

export default DateRangeSelector;
